<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <iq-card body-class=" profile-page p-0">
          <template v-slot:body>
            <div class="profile-header">
              <div class="cover-container">
                <b-img v-bind:src="companyInfo.background || require('../../assets/images/12booky-logo-black.jpg')" alt="profile-bg" rounded fluid class="w-100" style="max-height: 300px;
    object-fit: cover;"/>
                <ul class="header-nav d-flex flex-wrap justify-end p-0 m-0">
                  <li><b-link href="javascript:void(0);"><i class="ri-phone-fill"></i></b-link></li>
                </ul>
              </div>
              <div class="profile-info p-4">
                <b-row>
                  <b-col md="6" sm="12">
                    <div class="user-detail pl-5">
                      <div class="d-flex flex-wrap align-items-center">
                        <div class="profile-img pr-4">
                          <b-img v-bind:src="companyInfo.logo || require('../../assets/images/splash.png')" alt="profile-img" fluid class="avatar-130" />
                        </div>
                        <div class="profile-detail d-flex align-items-center">
                          <h3>{{ companyInfo.name }}</h3>
                        </div>
                        <p class="m-0 pl-3"> {{ companyInfo.address }}, {{ companyInfo.city }} </p>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="6" sm="12">
                    <tab-nav :pills="true" id="pills-tab" class="profile-feed-items d-flex justify-content-end">
                      <tab-nav-items :active="true" id="pills-feed-tab" href="#profile-about" ariaControls="pills-home" role="tab" :ariaSelected="true" title="About" />
                      <tab-nav-items :active="false" id="pills-activity-tab" href="#profile-book" ariaControls="pills-profile" role="tab" :ariaSelected="false" title="Rezerva" />
                    </tab-nav>
                  </b-col>
                </b-row>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col sm="12">
        <tab-content>
          <tab-content-item :active="true" id="profile-about" aria-labelled-by="pills-feed-tab">
            <b-row>
              <b-col lg="3" class="profile-left">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">About</h4>
                  </template>
                  <template v-slot:body>
                    <p class="news-detail mb-0" v-html="companyInfo.aboutUsText"></p>
                  </template>
                </iq-card>
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Galerie</h4>
                  </template>
                  <template v-slot:body>
                    <LightBox
                      ref="lightbox"
                      :media="media"
                      :show-caption="true"
                      :show-light-box="false"
                    />
                    <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                      <li v-for="(item,index) in media" :key="index" class="col-md-4 col-6 pl-1 pr-0 pb-1 pt-1">
                        <img @click="openGallery(index)" :src="item.thumb" style="height: 70px; width: 100%; object-fit: cover;"/>
                      </li>
                    </ul>
                  </template>
                </iq-card>
              </b-col>
              <b-col lg="6" class="profile-center">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Echipa</h4>
                  </template>
                  <template v-slot:body>
                    <div class="d-flex flex-wrap user-post-data mb-4 clearfix border-bottom" v-for="(item, key) in companyInfo.employeesList" :key="key">
                      <div class="media-support-user-img mr-3">
                        <b-img rounded="circle" fluid :src="item.logo" alt=""
                        style="height: 150px;width: 150px;object-fit: cover;"
                        />
                      </div>
                      <div class="media-support-info mt-2">
                        <h5 class="mb-0">{{ item.fname }} {{ item.lname }}</h5>
                        <p class="mb-0 text-secondary">{{ item.description }}</p>
                      </div>

                    </div>
                  </template>
                </iq-card>
              </b-col>
              <b-col lg="3" class="profile-right">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Program</h4>
                  </template>
                  <template v-slot:body>
                    <div class="" v-if="companyProgram.monday">Monday: {{ companyProgram.monday }}</div>
                    <div class="" v-if="companyProgram.tuesday">Tuesday: {{ companyProgram.tuesday }}</div>
                    <div class="" v-if="companyProgram.wednesday">Wednesday: {{ companyProgram.wednesday }}</div>
                    <div class="" v-if="companyProgram.thursday">Thursday: {{ companyProgram.thursday }}</div>
                    <div class="" v-if="companyProgram.friday">Friday: {{ companyProgram.friday }}</div>
                    <div class="" v-if="companyProgram.saturday">Saturday: {{ companyProgram.saturday }}</div>
                    <div class="" v-if="companyProgram.sunday">Sunday: {{ companyProgram.sunday }}</div>
                  </template>
                </iq-card>
                <iq-card v-show="companyInfo.map === 'true'">
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Harta</h4>
                  </template>
                  <template v-slot:body>
                    <p class="news-detail mb-0">
                      <gmap-map
                        ref="gmaps"
                        :center="{ lat: companyInfo.latitude, lng: companyInfo.longitude }"
                        :zoom="16"
                        style="width:100%;  height: 100px;"
                      ></gmap-map>
                    </p>
                  </template>
                </iq-card>
              </b-col>
            </b-row>
          </tab-content-item>
          <tab-content-item :active="false" id="profile-book" aria-labelled-by="pills-feed-tab">
            <calendar-book v-if="companyInfo.company" :theuser="user" :thecompanytoken="companyInfo.company"></calendar-book>
          </tab-content-item>
          <tab-content-item :active="false" id="profile-shop" aria-labelled-by="pills-feed-tab">
            <product-listing v-if="companyInfo.company" :theuser="user" :thecompanytoken="companyInfo.company" v-on:update_products="updateProducts" v-on:update_wishlist="updateWishlist"></product-listing>
          </tab-content-item>
        </tab-content>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AllCommunityModules } from '@ag-grid-enterprise/all-modules'
import Axios from 'axios'
import { API } from '@/config/api.config'
import LightBox from 'vue-it-bigger'
import 'vue-it-bigger/dist/vue-it-bigger.min.css'
import ProductListing from '@/views/Customer/ProductListing'
import CalendarBook from '@/views/Apps/Calendar/CalendarBook'

export default {
  name: 'CustomerServices',
  components: {
    ProductListing,
    CalendarBook,
    LightBox
  },
  data () {
    return {
      media: [],
      company: this.$route.params.company || '',
      paymentError: false,
      paymentInProgress: false,
      user: {
        payment: ''
      },
      paypalPayment: '',
      availablePayments: [],
      selectedServicePrice: 0,
      totalPrice: 0,
      discountPrice: 0,
      theDates: [],
      nextWeek: true,
      previousWeek: false,
      currentWeek: '',
      center: { lat: 45.508, lng: -73.587 },
      currentPlace: null,
      categories: [],
      theCategory: 'all',
      searchCompany: '',
      theRadius: 'any',
      radiuses: {
        any: this.$t('customer_.any'),
        0.5: '500m',
        1: '1km',
        2: '2km',
        3: '3km',
        5: '5km',
        10: '10km',
        20: '20km',
        50: '50km'
      },
      apiToken: localStorage.getItem('customer_api_token') || '',
      columnDefs: null,
      rowData: [],
      modules: AllCommunityModules,
      headers: [
        { text: this.$t('customer_.date'), value: 'start' },
        { text: 'ID', value: 'company' },
        { text: this.$t('customer_.service'), value: 'service' },
        { text: this.$t('customer_.employee'), value: 'employee_name' },
        { text: this.$t('customer_.total_to_pay'), value: 'total_to_pay', align: 'center' },
        { text: this.$t('customer_.total_payed'), value: 'total_payed', align: 'center' },
        { text: this.$t('employees_list.actions'), value: 'actions', align: 'right' }
      ],
      search: '',
      emailTo: '',
      emailText: '',
      currentUserEmail: '',
      singleMessage: '',
      optionsServices: [],
      searcheS: '',
      searche: '',
      selectedCompany: '',
      selectedCompanyName: '',
      displayCompanies: [],
      activeServices: [],
      activeServicesId: [],
      companyEmployees: [],
      companyInfo: {},
      companyProgram: [],
      selectedEmployee: '',
      companyEmployeesPictures: [],
      companyActiveEmployee: [],
      companyServices: [],
      priceCurrency: '',
      customerLatitude: '',
      customerLongitude: '',
      infoContent: '',
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      timer: null,
      visiblePayments: false,
      selectedDay: '',
      selectedTimeStart: '',
      selectedTimeEnd: '',
      paymentPrice: '',
      paymentCurrency: '',
      selectedServiceName: '',
      revolutInstance: '',
      descriptionEvent: ''
    }
  },
  mounted () {
    core.index()
    this.getCurrentUser()
    this.getCompanyInfos(this.company)
    this.selectedCompany = this.company
    this.getAvailablePayments(this.company)
    this.selectedCompanyName = 'aaaa'
    this.visiblePayments = false
    this.activeServices = []
    this.activeServicesId = []
    this.selectedEmployee = []
    this.$refs.eventID.value = null
    this.$refs.employeeToken.value = ''
    this.$refs.employeeName.innerHTML = this.$t('employees_list.add_employee')
    this.$refs.employeeEmail.innerHTML = ''
    this.$refs.employeeEmail.setAttribute('token', '')
    this.$refs.employeeLogo.setAttribute('src', '')
  },
  methods: {
    getCurrentUser () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.get(API.API_CUSTOMER_GET_CUSTOMER)
        .then((response) => {
          if (response.data.status === 'success') {
            this.user = JSON.parse(response.data.user)
          }
        }).catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    updateProducts () {
      this.$emit('update_products')
    },
    updateWishlist () {
      this.$emit('update_wishlist')
    },
    openGallery (index) {
      this.$refs.lightbox.showImage(index)
    },
    getAvailablePayments (companyToken) {
      if (this.device !== 'ios') {
        const dataPost = {
          company: companyToken
        }
        Axios.post(API.API_GET_AVAILABLE_PAYMENTS, dataPost)
          .then((response) => {
            if (response.data.status === 'success') {
              if (response.data.data.available_payments) {
                this.availablePayments = response.data.data.available_payments
              }
              this.priceCurrency = response.data.data.payment_currency
              if (response.data.data.payment_test) {
                this.testPayment = response.data.data.payment_test
              }
              if (response.data.data.payment_paypal) {
                const paymentPaypal = JSON.parse(response.data.data.payment_paypal)
                if (paymentPaypal.active === true) {
                  const paypalUserId = (this.testPayment === '1') ? paymentPaypal.address_test : paymentPaypal.address
                  this.initializePaypal(paypalUserId)
                }
              }
              if (response.data.data.payment_revolut) {
                const paymentRevolut = JSON.parse(response.data.data.payment_revolut)
                if (paymentRevolut.active === true) {
                  this.revolutUserId = (this.testPayment === '1') ? paymentRevolut.api_sandbox : paymentRevolut.api
                  this.revolutInstance = (this.testPayment === '1') ? 'sandbox' : 'prod'
                  this.initializeRevolut()
                }
              }
              if (response.data.data.payment_stripe) {
                const paymentStripe = JSON.parse(response.data.data.payment_stripe)
                if (paymentStripe.active === true) {
                  this.stripeUserId = (this.testPayment === '1') ? paymentStripe.test_publishable : paymentStripe.live_publishable
                }
              }
            }
          })
          .catch((error) => {
            core.showSnackbar('error', error.response)
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'auth1.login' })
            }
          })
      }
    },
    getCompanyInfos (companyToken) {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      const postData = {
        company: companyToken
      }
      Axios.post(API.API_CUSTOMER_GET_COMPANY_INFO, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.companyInfo = response.data.companyinfo
            this.media = this.companyInfo.servicesList
            this.companyProgram = this.companyInfo.program
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    }
  }
}
</script>

<style>
  .company{
    box-shadow: 1px 2px 9px 3px #888888;
    border-radius: 5px;
    cursor: pointer;
  }
  .company:hover{
    background: #eaf5ff;
  }
  .company_name{
    font-size: 20px;
    font-weight: bold;
    display: block;
    color: black;
    line-height: 22px;
  }
  .company_address{
    font-size: 14px;
    color: #808080;
  }
</style>
